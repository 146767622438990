<template>
  <v-container fluid>
    <v-row class="mt-1">
      <v-col>
        <btn-voltar to="/alertas-perguntas" />
      </v-col>
    </v-row>

    <alertas-perguntas-campos
      :carregando-salvar="carregandoSalvar"
      :carregando-buscar="carregandoBuscar"
      :validacao-formulario="validacaoFormulario"
      :alerta-pergunta="alertaPergunta"
      @salvar="salvar"
    />
  </v-container>
</template>

<script>
  import tiposOcorrencia from '@/api/tipos-ocorrencia'
  import alertasPerguntasApi from '@/api/alertas-perguntas'

  export default {
    components: {
      AlertasPerguntasCampos: () => import('./AlertasPerguntasCampos'),
    },

    data () {
      return {
        carregandoSalvar: false,
        carregandoBuscar: false,
        validacaoFormulario: {},
        alertaPergunta: {},
      }
    },

    mounted () {
      this.buscar()
    },

    methods: {
      async buscar () {
        try {
          this.carregandoBuscar = true

          const resposta = await alertasPerguntasApi.buscar(this.$route.params.id)

          this.alertaPergunta = resposta.data
        } catch (e) {
          this.$router.push('/alertas-perguntas', () => this.$snackbar.mostrar({ cor: 'error', mensagem: this.$erroApi._(e) }))
        } finally {
          this.carregandoBuscar = false
        }
      },

      async salvar (pergunta) {
        this.carregandoSalvar = true

        try {
          await alertasPerguntasApi.atualizar(pergunta.id, pergunta)

          this.carregandoSalvar = false

          this.$router.push('/alertas-perguntas', () => this.$snackbar.mostrar({ cor: 'success', mensagem: this.$mensagens._('sucesso_editar') }))
        } catch (e) {
          if (e.response.status === 422) {
            this.validacaoFormulario = this.$erroApi.validacao(e)
            return
          }

          this.$snackbar.mostrar({ cor: 'error', mensagem: this.$erroApi._(e) })
        } finally {
          this.carregandoSalvar = false
        }
      },
    },

  }
</script>
